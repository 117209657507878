<template>
  <div class='pb-5'>
    <dialog-confirmation
      :is-open='confirm'
      @close='confirm = false'
      @confirmed='changePaymentMethod'
      :description="`Realmente deseja alterar a forma de pagamento da sua assinatura para
      ${user && user.plan && user.plan.method && user.plan.method === 'credit_card' ? 'boleto bancário' : 'cartão de crédito'}?`"
    />

    <b-modal
      id='modal'
      size='lg'
      header-bg-variant='primary'
      header-text-variant='light'
      ref='modal'
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-html='message' />
      <b-input-group>
        <b-button block href='/web' class='mb-2 mb-sm-2'>OK</b-button>
      </b-input-group>
    </b-modal>

    <b-modal ref='cancelarPlano' id='cancelarPlano' scrollable hide-footer hide-header>
      <b-alert show variant='danger' v-if='errors || error'>
        {{ error }} {{ errors }}
      </b-alert>

      <p class='alert_cancelar_plano'>Atenção! Você tem certeza que deseja cancelar sua assinatura?</p>

      <b-row>
        <b-col sm='12'>
          <label class='label_input_password'>Digite o motivo do cancelamento do plano:</label>
          <b-form-textarea
            id='cancellationReason'
            size='sm'
            :state='completedCancellationReason'
            @keyup='checkCompletedCancellationReason()'
            v-model='cancellationReason'
            placeholder='Motivo do cancelamento'
          >
          </b-form-textarea>
          <b-form-invalid-feedback :state='completedCancellationReason'>
            Digite o motivo do cancelamento do plano
          </b-form-invalid-feedback>
        </b-col>

        <b-col sm='12' class='mt-3'>
          <label class='label_input_password'>Para continuar o cancelamento, confirme sua senha de login:</label>
          <b-form-input
            class='w-100'
            id='current-password'
            :type="showCurrentPassword ? 'text' : 'password'"
            v-model='password'
            placeholder='Digite sua senha'
            required
          ></b-form-input>

          <b-input-group-append
            class='showCurrentPassword'
            style='cursor: pointer'
            @click='showCurrentPassword = !showCurrentPassword'
          >
            <b-icon v-if='showCurrentPassword' font-scale='1.5' icon='eye-slash-fill'></b-icon>
            <b-icon v-else font-scale='1.5' class='pa-1' icon='eye-fill'></b-icon>
          </b-input-group-append>
        </b-col>
      </b-row>


      <section class='d-flex my-3'>
        <base-button
          block
          class='mr-1 w-50'
          @click='$refs.cancelarPlano.hide()'
          outlinePrimary
        >
          Manter assinatura
        </base-button>

        <b-overlay
          :show='disabled'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-50'
        >
          <base-button
            block
            :disabled='!password'
            @click='cancelPlan()'
            danger
          >
            Confirmar cancelamento
          </base-button>
        </b-overlay>
      </section>
    </b-modal>

    <section v-if='addCreditCard'>
      <div @click='addCreditCard = false' style='cursor:pointer'
           class='d-flex align-items-center justify-content-start my-2'>
        <img src='@/assets/images/return.svg' class='mb-2 mr-1' />
        <h5 class='ml-1'>Cancelar</h5>
      </div>

      <add-credit-card
        :dataToChangeSubscriptionPaymentMethod='dataToChangeSubscriptionPaymentMethod'
        @close='addCreditCard = false'
      />
    </section>

    <section v-else>
      <p class='ml-3' v-if='user.endDateExtraAccessTime && user.endDateExtraAccessTime > 0'>
        Tempo extra de acesso:
        <b>{{ user.endDateExtraAccessTime }} dia(s)</b> <br />

        O tempo extra de acesso será utilizado caso não exista um plano em vigor.
      </p>

      <section v-if="user.plan && user.plan.status !== 'canceled'" class='d-flex w-100'>
        <section class='w-50'>
          <section class='d-flex justify-content-between'>
            <section class='activePlan '>
              <p class='mb-4'>
                <strong class='title'>Plano</strong><br />
                <span class='description'>{{ planName }}</span>
              </p>

              <p v-if='user.plan.lastPayment' class='mb-4'>
                <strong class='title'>Última renovação</strong><br />
                <span class='description'>
                  {{ user.plan.lastPayment | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY') }}
                </span>
              </p>

              <p v-if='user.plan.dueDate' class='mb-4'>
                <strong class='title'>Validade do plano</strong><br />
                <span class='description'>
                  {{ user.plan.dueDate | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY') }}
                </span>
              </p>

              <p v-if="user.plan.dueDate && user.plan.plan && user.plan.plan != 'TRIAL'" class='mb-4'>
                <strong class='title'>Data de vencimento da Próxima cobrança</strong><br />
                <span class='description'>
                  {{ user.plan.dueDate | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY') }}
                </span>
              </p>

              <p v-if="user.plan.plan && user.plan.plan == 'TRIAL'" class='mb-4'>
                <strong class='title'>
                  O plano de teste grátis não gera nenhuma cobrança após o fim da sua vigência.
                </strong>
              </p>

              <p class='mb-4'>
                <strong class='title'>Status</strong><br />
                <span class='description'>
                  {{ statusToPtBr(user.plan.status) }}
                </span>
                <br />

                <span class='description' v-if="user.plan.status === 'pending' && user.plan.bankSlip">
                  Segunda via do boleto:
                  <a :href='user.plan.bankSlip' target='_blank'><strong>Clique aqui</strong></a> <br />
                  </span>
              </p>

              <p class='mb-4'>
                <strong class='title'>Dias de utilização</strong><br />
                <span class='description'>
                  {{ new Date() | moment('diff', user.plan.createdAt, 'days') }}
                </span>
              </p>
              <section class='mb-3' v-if='user.plan && user.plan.adhesionContractUrl'>
                  <Terms
                    title='CONTRATO DE ADESÃO'
                    :link='user.plan.adhesionContractUrl'
                    textLink='Tenha acesso ao contrato de adesão da Ho Fácil Web'
                  />
              </section>
            </section>

            <section v-if="user.plan && user.plan.plan && user.plan.plan === 'MO1HO'">
              <base-button
                class='btn_export'
                @click='showModalChangePaymentMethod()'
              >
                Alterar forma de pagamento para
                {{ user.plan.method === 'credit_card' ? 'boleto bancário' : 'cartão de crédito' }}
              </base-button>
            </section>
          </section>

          <section class='ml-3' v-if="user.plan && user.plan.plan !== 'TRIAL'">
            <base-button
              @click='showModalCancelarPlano()'
              danger
            >
              <b-icon
                class='mr-1'
                icon='x-circle'
                aria-hidden='true'>
              </b-icon>
              Cancelar assinatura
            </base-button>
          </section>

          <section v-if="user.plan && user.plan.status === 'paid' && user.plan.plan === 'TRIAL'">
            <p class='mx-3' >
              <strong class='text-danger'>Atenção: </strong>
              Seu teste grátis está ativo até <strong>{{formatDateDueDate(user.plan.dueDate)}}</strong>, todas as compras realizadas irá sobrepor o plano de teste grátis.
            </p>
          </section>
        </section>


        <section class='w-50' v-if="user.plan && user.plan.plan === 'TRIAL'">
          <b-card class='w-100'>
            <template v-slot:header>
              <h1 v-if='plans[plan]'>{{ plans[plan].name }}</h1>
            </template>
            <p class='planDescription' v-if='plans[plan]'>
              {{ plans[plan].description }}
            </p>
            <p class='planDescription' v-if='plans[plan]'>
              {{ plans[plan].refund }}
            </p>
            <p class='planValue' v-if='plans[plan]'>
              {{ plans[plan].value | addCurrency }}
            </p>

            <span class='notes'>Métodos de pagamento aceitos:</span>
            <div>
              <img alt='Amex' src='../../assets/images/pagamento/amex.svg' />
              <img alt='Boleto' src='../../assets/images/pagamento/boleto.svg' />
              <img
                alt='Dinner`s club'
                src='../../assets/images/pagamento/dinners.svg'
              />
              <img alt='ELO' src='../../assets/images/pagamento/elo.svg' />
              <img alt='Mastercard' src='../../assets/images/pagamento/master.svg' />
              <img alt='Visa' src='../../assets/images/pagamento/visa.svg' />
            </div>
            <span class='notes'>
              Ambiente e transações 100% seguros por
              <a href='https://iugu.com' target='_blank'>iugu</a>
            </span>
          </b-card>
        </section>
      </section>

      <div class='d-flex'>
        <section class='w-50 mr-5 p-3' v-if="!user.plan || (user.plan && user.plan.plan === 'TRIAL')">
          <b-form-group label='Aderir ao plano:'>
            <b-form-radio-group id='plan' v-model='plan' name='plan'>
              <b-form-radio value='AN1HO'>Combo Anual</b-form-radio>
              <b-form-radio value='MO1HO'>Assinatura Mensal</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <section v-if="plan === 'MO1HO'" class='obs my-3'>
            <img width='25' height='25' src='../../assets/images/Obs.svg' alt=''>

            <section class='ml-2'>
              <p class='ml-2 mt-3 obs-text'>
                Reforçamos que a assinatura mensal é <strong>cobrada de forma automática </strong> todo mês a menos que
                cancelada de forma <strong>manual.</strong>
              </p>

              <section class='ml-2 limits '>
                <strong>Limites do plano mensal:</strong>
                <ul class='limits mt-1'>
                  <li class='obs-text'>Pesquisa e seleção de até 300 agentes químicos por mês</li>
                  <li class='obs-text'>Sem informações precisas e segurança para fundamentar o seu GRO (Gerenciamento de Riscos Ocupacionais)</li>
                  <li class='obs-text'>Sem permissão para adquirir acessos adicionais</li>
                </ul>
              </section>
            </section>
          </section>
          <b-input-group>
            <b-button id='next' block @click='subscribe' class='mb-2 mb-sm-2'>Confirmar</b-button>
          </b-input-group>
        </section>

        <b-card class='w-50' v-if='!user.plan'>
          <template v-slot:header>
            <h1 v-if='plans[plan]'>{{ plans[plan].name }}</h1>
          </template>
          <p class='planDescription' v-if='plans[plan]'>
            {{ plans[plan].description }}
          </p>
          <p class='planDescription' v-if='plans[plan]'>
            {{ plans[plan].refund }}
          </p>
          <p class='planValue' v-if='plans[plan]'>
            {{ plans[plan].value | addCurrency }}
          </p>

          <span class='notes'>Métodos de pagamento aceitos:</span>
          <div>
            <img alt='Amex' src='../../assets/images/pagamento/amex.svg' />
            <img alt='Boleto' src='../../assets/images/pagamento/boleto.svg' />
            <img
              alt='Dinner`s club'
              src='../../assets/images/pagamento/dinners.svg'
            />
            <img alt='ELO' src='../../assets/images/pagamento/elo.svg' />
            <img alt='Mastercard' src='../../assets/images/pagamento/master.svg' />
            <img alt='Visa' src='../../assets/images/pagamento/visa.svg' />
            <img class='mb-1 ml-1' style='box-shadow: rgba(100, 100, 111, 0.2) 1px 2px 2px 1px; padding: 2px 5px; border-radius: 3px' width="56" height="36" alt='PIX' src='../../assets/images/pagamento/pix.svg' />
          </div>
          <span class='notes'>
            Ambiente e transações 100% seguros por
            <a href='http://iugu.com' target='_blank'>iugu</a>
        </span>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import AddCreditCard from '@/components/CreditCard/AddCreditCard'
import Terms from '@/components/Terms.vue'
import moment from 'moment/moment'

export default {
  name: 'ActivePlan',
  props: {},
  components: {
    Terms,
    DialogConfirmation,
    AddCreditCard
  },
  data() {
    return {
      confirm: false,
      dataToChangeSubscriptionPaymentMethod: null,
      addCreditCard: false,
      password: '',
      cancellationReason: '',
      completedCancellationReason: null,
      message: '',
      showCurrentPassword: false,
      attempt: false,
      disabled: false,
      errors: '',
      plans: {},
      plan: 'AN1HO'
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Minha conta',
        active: false
      },
      tabs: [
        {
          name: 'Meus planos',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    this.plans = (await this.$api.get(`plans`)).data
  },
  filters: {
    addCurrency(value) {
      return `R$ ${parseFloat(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2
      })}`
    }
  },
  computed: {
    error() {
      return this.$store.getters['checkout/error']
    },
    profile() {
      return this.$store.getters['checkout/profile']
    },
    user() {
      return this.$store.getters['user/current']
    },
    planName() {
      if (this.user.plan.plan === 'MO1HO')
        return 'Mensal'
      if (this.user.plan.plan === 'AN1HO')
        return 'Anual'
      if (this.user.plan.plan === 'PROMO')
        return 'Promocional'
      if (this.user.plan.plan === 'TRIAL')
        return 'Teste grátis'
      return 'Personalizado'
    }
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('checkout2', {
      handleChangeSubscriptionPaymentMethod: 'handleChangeSubscriptionPaymentMethod'
    }),
    formatDateDueDate(date) {
      const dueDate = new Date(date)
      return moment(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate(), 23, 59, 59)).format('DD/MM/YYYY HH:mm:ss')
    },
    checkCompletedCancellationReason() {
      this.completedCancellationReason = !!(this.cancellationReason && this.cancellationReason.length > 0)
    },
    async changePaymentMethod() {
      this.dataToChangeSubscriptionPaymentMethod = {
        planId: this.user.plan._id,
        subscriptionId: this.user.plan.subscriptionId,
        paymentMethod: this.user.plan.method === 'credit_card' ? 'bank_slip' : 'credit_card'
      }

      if (this.user.plan.method === 'credit_card') {
        const {
          data,
          status
        } = await this.handleChangeSubscriptionPaymentMethod(this.dataToChangeSubscriptionPaymentMethod)

        let message = data.message
        let variant = 'danger'
        if (status === 200) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.confirm = false

        const sessionId = localStorage.getItem('sessionId')
        const user = (await this.$api.get(`users/${sessionId}`)).data
        await this.$store.dispatch('user/updateUser', user)
        return
      }

      this.confirm = false
      this.addCreditCard = true
    },
    showModalChangePaymentMethod() {
      this.confirm = true
    },
    statusToPtBr(status) {
      if (status === 'paid') return 'Ativo'
      if (status === 'pending') return 'Aguardando pagamento'
      if (status === 'outdated') return 'Cancelado por vencimento da cobrança'
      else return 'vencido'
    },
    showModalCancelarPlano() {
      this.completedCancellationReason = null
      this.$refs.cancelarPlano.show()
    },
    async cancelPlan() {
      this.checkCompletedCancellationReason()
      if (!this.completedCancellationReason) {
        return
      }
      this.disabled = true
      const { data } = await this.$store.dispatch('plan/cancel', {
        password: this.password,
        planId: this.user.plan._id,
        cancellationReason: this.cancellationReason
      })
      if (data === 'Sua senha não confere, tente novamente!') {
        this.errors = data
        this.disabled = false
      } else {
        this.message = data.message
        this.profile.plan = undefined
        const sessionId = localStorage.getItem('sessionId')
        const user = (await this.$api.get(`users/${sessionId}`)).data
        await this.$store.dispatch('user/updateUser', user)
        this.$bvModal.hide('cancelarPlano')
        this.$bvModal.show('modal')
      }
    },
    async subscribe() {
      await this.$router.push({
        name: 'Checkout', query: {
          plano: this.plan,
          utm_source: 'app',
          utm_medium: 'free',
          utm_campaign: 'vazio',
          utm_term: 'meu-plano',
          utm_content: 'vazio'
        }
      })
    }
  },

  watch: {}
}
</script>

<style lang='scss' scoped>
.activePlan {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  padding: 7px 15px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #262626;

}

.description {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #808080;
  margin: 5px 0;
}

.alert_cancelar_plano {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #262626;
  margin-bottom: 30px;
}

.label_input_password {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.1em;
  color: #808080;
}

.showCurrentPassword {
  position: absolute;
  right: 25px;
  top: 33px
}

.column-panel {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.column-panel > div {
  padding: 15px;
}

.centered {
  width: 600px;
  margin: 0px auto;
}

.error {
  border: 1px solid red;
}

.inline-form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.planValue {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  display: block;
  text-align: center;
}

.card-header h1 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #999;
  padding: 0;
  margin: 0;
}

.planDescription {
  font-style: italic;
}

.notes {
  font-size: 10px;
}

.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 8px;
  font-style: normal;
  font-weight: 500;
}

.obs-text {
  color: var(---navy-blue);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.limits{
  list-style: inside;
  margin-left: 30px;
  li{
    margin-bottom: 5px;
    list-style-position: outside;
  }
}
</style>
